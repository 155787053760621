@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "vars";
@import "./Layout/header";
@import "./Layout/crm";
@import "./Layout/system";
@import "./Layout/sucsecret";
@import "./Layout/navbar";
@import "./Layout/contactus";
* {
    font-family: $font-family; }
.bg-light-primary {
    background: $primary-light !important; }
.bg-light-danger {
    background: $danger-light !important; }
.bg-primary {
    background: $primary-color !important; }
.bg-danger {
    background: $danger-color !important; }

.w-30 {
    width: 30%; }
