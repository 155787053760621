
header {
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    .container-fluid {
        //background: rgba(38, 60, 118, 0.55)
        h1 {
            font-size: 60px; }
        img {
            filter: brightness(10); } } }

@media screen and (max-width: 760px) {
    header {
        .container-fluid {
            padding: 0 !important; }
        h1 {
            font-size: 30px !important; } } }

